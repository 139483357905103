"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postFormDataAsJson = exports.formInit = void 0;
function formInit(url) {
    Array.from(document.getElementsByTagName("form")).forEach(async (form) => {
        if (form.getAttribute('id') != "searchform") {
            if (form.classList.contains('webinar') && localStorage.getItem('curo')) {
                form.innerHTML = '<div style="padding:56.25% 0 0 0" class="position-relative"><div class="position-absolute d-block spinner spinner-large spinner-dust" style="top:25%;"></div></div>';
                // submit a request and replace with video
                let formData = new FormData();
                formData.append('id', localStorage.getItem('curo'));
                formData.append('type', 'webinar');
                formData.append('webinar_id', form.dataset['webinar']);
                submitForm(form, formData, url);
            }
            if (form.classList.contains('download') && localStorage.getItem('curo')) {
                let formData = new FormData();
                formData.append('id', localStorage.getItem('curo'));
                formData.append('type', 'download');
                if (form.dataset['page']) {
                    form.innerHTML = '<div style="padding:56.25% 0 0 0" class="position-relative"><div class="position-absolute d-block spinner spinner-large spinner-dust" style="top:25%;"></div></div>';
                    //redirect straight to guide page
                    //window.location.href = window.location.href.replace("/guide/", "/guides/");
                    formData.append('page_id', form.dataset['page']);
                    submitForm(form, formData, url);
                }
                else {
                    //show download button
                    let button = form.querySelector('button');
                    // replace with a button that will download the document
                    // let button:HTMLButtonElement = document.createElement('button');
                    // button.innerHTML = '<span>Download our guide</span><span class="spinner spinner-dust"></span>'
                    // button.className = 'btn rounded-pill btn-outline-dust'
                    button.addEventListener('click', async (event) => {
                        event.preventDefault();
                        formData.append('document_id', form.dataset['document']);
                        submitForm(form, formData, url);
                    });
                    form.innerHTML = "<p>As you've already completed our download form, click the button below to download this guide</p>";
                    form.classList.add('download-prompt', 'bg-primary', 'text-center', 'text-sm-start', 'p-3', 'rounded', 'shadow');
                    form.appendChild(button);
                }
            }
            form.onsubmit = async (event) => {
                form.classList.add('was-validated');
                if (!form.checkValidity()) {
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                }
                // disable form buttons
                disableButtons(form);
                // hide any previous message
                let messageEl = document.getElementById('form-message');
                if (messageEl)
                    messageEl.remove();
                event.preventDefault();
                const formData = new FormData(form);
                submitForm(form, formData, url);
                // prevent reload
                return false;
            };
        }
    });
}
exports.formInit = formInit;
// Function to trigger the event with payload
function triggerFormSubmissionEvent(email) {
    const encodedEmail = btoa(email); // Encoding email to base64
    // Pushing data layer event with payload
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'form_submission',
            email: encodedEmail
        });
    }
}
async function submitForm(form, formData, url) {
    try {
        const responseData = await postFormDataAsJson(url, formData);
        handleResponse(responseData, form);
    }
    catch (error) {
        let div = document.createElement('div');
        div.className = 'alert bg-premier shadow rounded text-dust';
        div.id = 'form-message';
        div.textContent = error.message;
        if (error.message == 'Id not found') {
            // unset curo id from localstorage
            localStorage.removeItem('curo');
            div.textContent = 'There was error completing this request, please refresh the page and try again.';
        }
        form.before(div);
        // form.remove()
        enableButtons(form);
    }
}
async function postFormDataAsJson(url, formData) {
    let plainFormData = Object.fromEntries(formData.entries());
    if (blacklistEmail(plainFormData.email)) {
        throw new Error("There was error completing this request, please refresh the page and try again.");
    }
    // if utm data is in localStorage, merge with formData
    if (localStorage.getItem('utm')) {
        try {
            plainFormData = Object.assign(plainFormData, JSON.parse(localStorage.getItem('utm')));
        }
        catch (error) {
            // ignore, just sends the plainFormData
        }
    }
    // GTM event
    triggerFormSubmissionEvent(plainFormData.email);
    let formDataJsonString = JSON.stringify(plainFormData);
    let fetchOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: formDataJsonString,
    };
    let response = await fetch(url, fetchOptions);
    if (!response.ok) {
        let errorMessage = await response.text();
        throw new Error(errorMessage);
    }
    return response.json();
}
exports.postFormDataAsJson = postFormDataAsJson;
function handleResponse(responseData, form) {
    // add success message to replace form
    form.classList.add('form-success');
    let div = document.createElement('div');
    div.id = 'form-message';
    // handle the responseData 
    if (responseData.document) {
        div.className = 'alert bg-dark text-light rounded shadow form-message-asset';
        div.innerHTML = 'Thank you, your requested guide should download as a PDF, or you can <a class="text-light" href="' + responseData.document + '" target="_blank">click here</a>';
        setTimeout(() => {
            window.location.href = responseData.document;
        }, 1000);
    }
    else if (responseData.url) {
        div.className = 'alert bg-dark text-light rounded shadow ' + responseData.message_class;
        div.textContent = 'Thank you, you\'ll be redirected to the requested content';
        window.location.href = responseData.url;
        // setTimeout(()=>{
        //     window.location.href = responseData.url
        // }, 250)
    }
    else if (responseData.webinar) {
        div.className = 'form-message-asset';
        div.innerHTML = `
        <div style="padding:56.25% 0 0 0" class="position-relative"><iframe title="Video" src="https://player.vimeo.com/video/${responseData.webinar}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=e3495cf78d" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>`;
    }
    else {
        div.className = 'alert bg-premier text-dust rounded shadow';
        div.textContent = 'Thank you for taking the time to get in touch. We’ll get back to you as soon as possible.';
    }
    if (responseData.id) {
        const itemSet = (localStorage.getItem('curo') !== null);
        if (itemSet) {
            // do nothing
        }
        else {
            localStorage.setItem('curo', responseData.id);
        }
    }
    form.before(div);
    form.classList.remove('was-validated');
    form.reset();
    // remove the form from the page, prevent resubmit with same nonce
    form.remove();
    enableButtons(form);
}
function disableButtons(form) {
    Array.from(form.getElementsByTagName('button')).forEach(button => {
        button.setAttribute('disabled', 'disabled');
    });
}
function enableButtons(form) {
    Array.from(form.getElementsByTagName('button')).forEach(button => {
        button.removeAttribute('disabled');
    });
}
function blacklistEmail(email) {
    const client = email.split("@");
    const match = client[1].match(/(mailinator\.)/);
    return match;
}
const elements = document.querySelectorAll("INPUT, TEXTAREA");
//console.log(elements,'els')
for (var i = 0; i < elements.length; i++) {
    var el = elements[i];
    el.addEventListener('invalid', function (event) {
        var target = event.target;
        if (target.validity.patternMismatch && !target.validity.valueMissing) {
            target.parentElement.classList.add('invalid-pattern');
        }
    });
    el.addEventListener('change', function (event) {
        var target = event.target;
        if (target.validity.patternMismatch && !target.validity.valueMissing) {
            target.parentElement.classList.add('invalid-pattern');
        }
        if (!target.validity.patternMismatch) {
            target.parentElement.classList.remove('invalid-pattern');
        }
    });
}
